<template>
    <div>
        <!-- <hr> -->
            <div class="container">
                <div class="row">
                <aside class="col-xs-12 col-sm-12 col-md-12 col-4  d-none d-lg-none d-sm-block">
                    <div class="row">
                    <div class="col-12 mb-2"><small><strong>DISCOVER MORE OF WHAT MATTERS TO YOU</strong></small></div>
                    </div>
                    <div class="row">
                    <div class="col">
                    <ul class="list-inline">
                        <li class="list-inline-item btn btn-outline-secondary mb-1" v-for="item in tags" :key="item.undex"> <small> {{ item.title }}</small></li>
                    </ul>
                    <hr>
                    </div>
                    </div>
                </aside>
                </div>
            </div>
            <!-- <hr> -->

            <div class="container py-5">
                <div class="row">

                <article class="col-xs-12 col-sm-12 col-md-12 col-lg-8 mt-2">
                    <!-- Article 1 -->
                    <template v-if="isloading">
                        <div class="mb-3 d-flex row" v-for="item in items" :key="item.index">
                            <div class="col-8 col-md-8">                                                    
                                <v-skeleton-loader
                                class="mx-auto"
                                max-width="600"
                                type="paragraph"
                                ></v-skeleton-loader>
                            </div>
                            <figure class="col-4 col-md-4">
                                <v-skeleton-loader
                                class="mx-auto"
                                max-width="600"
                                max-height="100"
                                type="image"
                                ></v-skeleton-loader>
                            </figure>
                        </div>
                    </template>
                    <div v-else class="row mb-4" v-for="post in data" :key="post.index" style="cursor: pointer;">
                    <div class="col-12 col-sm-8">
                        <div class="col-12">
                        <small>
                        <img class="rounded-circle" :src="post.owner_image" alt="" style="margin-right: 7px; height: 30px; width: 30px">   
                            {{ post.owner }}
                        <span class="text-muted">in</span>
                        Random
                        </small> <br>
                        <router-link :to="`${post.url}`">
                        <h5 class="font-weight-bold my-2">
                            {{ post.title }}
                        </h5>
                        </router-link>
                        <h6 class="text-muted">{{ post.description }}.</h6>
                        <ul class="list-inline text-muted">
                            <li class="list-inline-item"> <small>{{ post.updated_date }}</small></li>
                            <li class="list-inline-item"> <small>.</small></li>
                            <li class="list-inline-item"> <small>{{ post.reading.text }}</small></li>
                            <li class="list-inline-item small"> <small><i class="fas fa-star"></i></small></li>
                            <li class="list-inline-item float-right" v-if="$store.state.user.auth">
                                <span class="like-btn" :id="`ac-${post.url}`" @click="like(post)" :class="(post.like) ? 'like-active' : ''"></span>
                            </li>
                            </ul>
                    </div>
                    </div>
                    <div class="col-4 thumbail-item" style="max-height: 155px;">
                        <img class="w-100 border-radius d-none d-sm-block thumbail" :src="post.thumbail_url" alt="">
                    </div>
                    </div>
                </article>


                <!-- Discover More Section - Start -->
                <aside class="col-xs-12 col-sm-12 col-md-12 col-lg-4  d-none d-sm-none d-lg-block">
                <div class="row">
                    <div class="col-12 mb-2"><small><strong>DISCOVER MORE OF WHAT MATTERS TO YOU</strong></small></div>
                </div>
                <div class="row">
                    <div class="col">
                        <template v-if="isloading">
                            <v-skeleton-loader
                            v-for="item in items" :key="item.index"
                            max-width="50"
                            type="text"
                            ></v-skeleton-loader>
                        </template>
                    <ul class="list-inline" v-else>
                        <li class="list-inline-item btn btn-outline-secondary mb-1" v-for="item in tags" :key="item.index" @click="$router.push(`/category/${item.url_perma}/us`)"> <small> {{ item.title }} </small> </li>
                    </ul>
                    <hr>
                    </div>
                </div>
                </aside>
                <!-- Discover More Section - End -->


                </div>
                
            </div>
    </div>    
</template>
<script>
import axios from 'axios'
import $ from 'jquery'
import readingTime from 'reading-time'
import moment from 'moment'

export default {
    data() {
        return {
            tags: '',
            data: '',
            randomPick: '',
            isActive: false,
            isloading: false,
            items: [
                { message: 'Foo' },
                { message: 'Bar' },
                { message: 'Bar' }
            ]
        }
    },
    mounted() {
        this.getCategory()
        this.getData()
    },
    methods: {
        async getCategory() {
            this.isloading = true
            const data = await axios.get('category/getAll').finally(() => { this.isloading = false })
            this.tags = data.data.data
        },
        like(oi) {
            $('#ac-' + oi.url).toggleClass('like-active')
            if (!oi.like) {
                axios.post('like/post', {id_post: oi.id})
            } else {
                axios.post('like/un', {id_post: oi.id})
            }
        },
        async getData() {
            this.isloading = true
            const data = await axios.get('posting/getAll').finally(() => { this.isloading = false })
            let arrayData = Array()
            data.data.data.forEach(async (element) => {
                let toPush = ''
                // let islike = [false]
                const startDesc = element.description.indexOf('<p class="blog_post_text text-left">')
                if (startDesc < 0) {
                    toPush = 'Nothing paragraph in here'  
                } else {
                    const jadi_string = $(element.description).text().trim().substr(0, 50)
                    toPush = jadi_string
                } 
                const islike = await element.likePosts.filter((el) => { return (el.id_users == this.$store.state.user.user.id && el.id_posts == element.id) ? true : false })
                const time = readingTime($(element.description).text().trim())
                const data = {
                    id: element.id,
                    title: element.title,
                    description: toPush,
                    thumbail_url: element.thumbail_url,
                    owner: element.ownerPosting.username,
                    url: element.url_perma,
                    like: (islike.join('')) ? true : false,
                    owner_image: element.ownerPosting.profile_photo,
                    updated: (element.date_updated != "non") ? true : false,
                    updated_date: (element.date_updated != "non") ? moment(element.date_updated).format('MMMM D') : moment(element.date_created).format('MMMM D'),
                    reading: time
                }
                arrayData.push(data)
            });
            
                this.data = arrayData
            },
    }
}
</script>
<style scoped>
.thumbail {
    object-fit: cover;
    height: 100%;
}
.like-btn {
    display: inline-block;
    Cursor: pointer;
    width: 57px;
    height: 50px;
    position: relative;
    top: -12px;
}

.like-btn {
  background: url('https://i.ibb.co/vw78mf3/heart.png') no-repeat 0% 50%;
  background-size: 2900%;
}


.like-active {
  animation-name: animate;
  animation-duration: .8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.like-active {
  animation-timing-function: steps(28);
}

@keyframes animate {
  0%   { background-position: left;  }
  50%  { background-position: right; }
  100% { background-position: right; }
}
</style>