import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import Toasted from 'vue-toasted'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'

require('@/store/subscriber')
Vue.use(VueMeta)
Vue.use(Toasted)
Vue.config.productionTip = false
axios.defaults.baseURL = 'https://app-1e7e6e52-8568-4b32-a1e4-2e0c237d83e5.cleverapps.io'

store.dispatch('user/attempt',localStorage.getItem('sesSuccess')).then(() => { 
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})
