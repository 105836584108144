<template>
    <div>
        <div class="ct ia hc ql qm" data-popper-reference-hidden="false" data-popper-escaped="false" data-popper-placement="bottom" :style="`position: absolute; inset: 0px auto auto 0px; transform: translate(${(rightLeft) ? rightLeft : '1045px'}, 74px);width: 20%;`">
            <div class="qy hc" style="overflow-y: auto; max-height: 260.51px;">
                <div id="metabarActionsMenu" class="qy ib"><span tabindex="0"></span>
                <div class="qz af">
                    <ul class="lb ra ar rb do">
                    <li class="rc af">
                        <div class="ca b cb cc ie">
                        <div>
                            <div class="hb if ar bv"><router-link :to="`/@${this.$store.state.user.user.username}/me`"><img alt="Manusiayangsempurna" class="af kd kq rd" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7BXX2cI7TyRkA5bigxxWq8BNDBmrUbQDlsA&usqp=CAU" width="40" height="40"></router-link>
                            <div class="jd ar do"><a class="ig je bf bg bh bi bj bk bl bm jf jg bp lt lu" rel="noopener" :href="`/@${this.$store.state.user.user.username}/me`"><span class="ca b cb cc cd">{{ this.$store.state.user.user.name }}</span></a><a class="bd be bf bg bh bi bj bk bl bm re bp bq br" rel="noopener" href="/@manusiayangsempurna762"><span class="ca b cb cc ie">@{{ this.$store.state.user.user.username }}</span></a></div>
                            </div>
                        </div>
                        </div>
                    </li>
                    <li class="lb ra af">
                        <div class="eu"></div>
                    </li>
                    <li class="rc af">
                        <div class="ca b cb cc ie"><a href="/iam/write" class="bd be bf bg bh bi bj bk bl bm bn bo bp bq br" rel="noopener">Write a story</a></div>
                    </li>
                    <li class="rc af">
                        <div class="ca b cb cc ie"><a class="bd be bf bg bh bi bj bk bl bm bn bo bp bq br" rel="noopener" href="/me/write">Stories</a></div>
                    </li>
                    <li class="rc af">
                        <div class="ca b cb cc ie"><a href="/me/settings" class="bd be bf bg bh bi bj bk bl bm bn bo bp bq br" rel="noopener">Settings</a></div>
                    </li>
                    <li class="lb ra af">
                        <div class="eu"></div>
                    </li>
                    <li class="rc af">
                        <div class="ca b cb cc ie"><a href="/partner" class="bd be bf bg bh bi bj bk bl bm bn bo bp bq br" rel="noopener">Random Partner</a></div>
                    </li>
                    <li class="lb ra af">
                        <div class="eu"></div>
                    </li>
                    <li class="rc af">
                        <div class="ca b cb cc ie"><a href="/help/iw" class="bd be bf bg bh bi bj bk bl bm bn bo bp bq br" rel="noopener">Help</a></div>
                    </li>
                    <li class="rc af">
                        <div class="ca b cb cc ie"><button class="bd be bf bg bh bi bj bk bl bm bn bo bp bq br" @click="logout()">Sign out</button></div>
                    </li>
                    </ul>
                </div><span tabindex="0"></span>
                </div>
                <div class="aw ik il im in io ip iq ir is it iu" style="position: absolute; left: 0px; transform: translate(113px, 0px);"></div>
            </div>
        </div>  
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props: {
        rightLeft: String,
    },
    methods: {
        ...mapActions({
            signout: 'user/logout'
        }),
        logout() {
            this.signout().then(() => {
                window.location.reload()
            })
        }
    }
}
</script>
<style scoped>
.qm {
    z-index: 700;
}
.ql {
    box-shadow: rgb(230 230 230) 0px 1px 4px;
}
.ia {
    border: 1px solid rgba(230, 230, 230, 1);
}
.hc {
    border-radius: 4px;
}
.ct {
    box-sizing: border-box;
}
.qy {
    background: rgb(255, 255, 255);
}
.hc {
    border-radius: 4px;
}
.ib {
    border-radius: 3px;
}
.qy {
    background: rgb(255, 255, 255);
}
.qz {
    max-width: 240px;
}
.rb {
    align-items: stretch;
}
.ra {
    padding-top: 8px;
}
.lb {
    padding-bottom: 8px;
}
.do {
    flex-direction: column;
}
menu, ol, ul {
    padding: 0;
    list-style: none;
    list-style-image: none;
}
.rc {
    padding: 8px 20px;
}
.ie {
    color: rgba(117, 117, 117, 1);
}
.cc {
    line-height: 20px;
}
.cb {
    font-size: 14px;
}
.b {
    font-weight: 400;
}
.if {
    margin-top: 12px;
}
.hb {
    margin-bottom: 20px;
}
.bv {
    align-items: center;
}
.ar {
    display: flex;
}
.rd {
    width: 40px;
}
.kq {
    height: 40px;
}
.kd {
    border-radius: 50%;
}
.af {
    display: block;
}
img, svg {
    vertical-align: middle;
}
.jd {
    margin-left: 16px;
}
.do {
    flex-direction: column;
}
.ar {
    display: flex;
}
.cd {
    color: rgba(41, 41, 41, 1);
}
.cc {
    line-height: 20px;
}
.cb {
    font-size: 14px;
}
.ca {
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.ra {
    padding-top: 8px;
}
.lb {
    padding-bottom: 8px;
}
.af {
    display: block;
}
.eu {
    border-top: 1px solid rgba(230, 230, 230, 1);
}
.rc {
    padding: 8px 20px;
}
.af {
    display: block;
}
.ie {
    color: rgba(117, 117, 117, 1);
}
.cc {
    line-height: 20px;
}
.cb {
    font-size: 14px;
}
.in {
    clip: rect(0px, 18px, 14px, -4px);
}
.il {
    top: -14px;
}
.is::after {
    background: rgb(255, 255, 255);
}
.ir::after {
    height: 14px;
}
.iq::after {
    width: 14px;
}
.ip::after {
    display: block;
}
.io::after {
    content: "";
}
.it::after {
    transform: rotate(
45deg
) translate(6px, 6px);
}
.iu::after {
    box-shadow: rgb(117 117 117) -1px -1px 1px -1px;
}
</style>