<template>
  <div class="home">
    <Navbar boxShadow="false" jenis="nothing"/>
    <Hero style="margin-top: 42px"/>
    <Trending/>
    <PostHome/>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/layouts/Navbar.vue'
import Hero from '@/components/Hero.vue'
import Trending from '@/components/Trending.vue'
import PostHome from '@/components/PostHome.vue'

export default {
  name: 'Home',
  components: {
    Navbar,
    Hero,
    Trending,
    PostHome
  },
  metaInfo() {
      return { 
          title: "Random - Build your network in your field of interest",
          meta: [
              { name: 'description', content:  'Random is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.'},
              { property: 'og:title', content: "Random - Build your network in your field of interest"},
              { property: 'og:site_name', content: 'Random'},
              { property: 'og:type', content: 'website' },    
              { name: 'robots', content: 'index,follow' },
          ]
      }
  }
}
</script>
