<template>
    <div>
        <div class="bg-medium-yellow main">
            <div class="container pt-5">
                <div class="row">
                <div class="col-8 pb-5">
                    <h1 class="display-3 py-3">
                        <strong>Where good ideas <br> find you</strong>
                    </h1>
                    <p class="lead py-2">Read and share new perspectives on just about any topic. Everyone’s <br> welcome. <a href="#" class="text-dark"> <u> Learn more.</u></a></p>
                    <a href="" class="btn btn-outline-dark rounded-pill">Get Started</a>
                </div>
                <div class="col-4">
                    <img class="rounded-circle py-4 overf" style="width: 72%;" src="@/assets/hero-image.png" alt="">
                </div>
            </div>
            </div>       
        </div>
    </div>    
</template>
<script>
export default {
    
}
</script>
<style scoped>

</style>